import React from "react";
import { Link } from "react-router-dom";
import snape from '../assets/snape.png'
import Wishlist from '../assets/Wishlist.png'
import AddNotes from '../assets/AddNotes.png'
import BookList from '../assets/BookList.png'
import Recommended from '../assets/Recommended.png'

import './HomePage.css';

function HomePage() {
  return (
    <div className="container">
      {/* Hero section */}
      <div className="jumbotron text-center mt-5">
        <h1 className="display-4">Welcome to StoryChats</h1>
        <p className="lead">
          Engage with your favorite stories like never before.
        </p>
        <img
          src={snape}
          alt="Your product"
          className="w-100 mx-auto mt-4" />
      </div>

      {/* Features section */}
<div className="my-5">
  <h2 className="text-center mb-5">Key Features</h2>
  <div className="row">
    {/* Feature 1 */}
    <div className="col-md-4 text-center">
      <div className="image-container">
        <img
          src={BookList}
          alt="Feature 1"
          className="mb-3" />
      </div>
      <h3>Track Books</h3>
      <p>
        Track the books you have read
      </p>
    </div>
    {/* Feature 2 */}
    <div className="col-md-4 text-center">
      <div className="image-container">
        <img
          src={Recommended}
          alt="Feature 2"
          className="mb-3" />
      </div>
      <h3>Recommendations</h3>
      <p>
        Get AI recommendations based on the books you have read
      </p>
    </div>
    {/* Feature 3 */}
    <div className="col-md-4 text-center">
      <div className="image-container">
        <img
          src={AddNotes}
          alt="Feature 3"
          className="mb-3" />
      </div>
      <h3>Add Notes</h3>
      <p>
        Add Notes to the books, or copy paste Chats you want to Save
      </p>
    </div>
  </div>
</div>

      {/* Call to action section */}
      <div className="bg-primary text-white py-4">
        <div className="text-center">
          <h2 className="mb-4">Get Started with StoryChats Today</h2>
          <p className="mb-4">
            Experience a new way to interact with your favorite stories.
          </p>
          <Link to="/register" className="btn btn-light">
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
