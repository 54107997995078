import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
const base64 = require('js-base64').Base64;

const LocalApiUrl = process.env.REACT_APP_API_BASE_URL;

const Register = () => {
  const Navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    city: "",
  });
  const [error, setError] = useState(null);

  const encryptUserId = (userId) => {
    const secret_key = process.env.REACT_APP_SECRET_KEY || "Yq3t6v9y";
    if (!secret_key) {
      console.error("Secret key is not set.");
      return null;
    }
    try {
      const encrypted = CryptoJS.AES.encrypt(userId.toString(), secret_key).toString();
      const urlSafeBase64String = base64.encode(encrypted, true); // Second argument enables URL-safe encoding
      return urlSafeBase64String
    } catch (err) {
      console.error("Error encrypting user ID:", err);
      return null;
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${LocalApiUrl}/user/register`, user);
      const encryptedUserId = encryptUserId(response.data.userId);
      if (encryptedUserId) {
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("encryptedUserId", encryptUserId)
        Navigate("/login");
      } else {
        setError("Failed to encrypt user ID.");
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 409) {
        setError("There is already an account with this email address, please login.");
      } else {
        setError("Something went wrong. Please ensure you're using a valid email and a strong password.");
      }
    }
  };

  return (
    <div className="container w-50 a-center">
      <h2>Register</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        {/* Existing fields */}
        <div
 
className="form-group">

          
<label>Email</label>

          
<input

            
type="email"

            
name="email"

            
className="form-control"

            
value={user.email}

            
onChange={handleChange}
            required
          />

        
</div>

        
<div
 
className="form-group">

          
<label>Password</label>

          
<input

            
type="password"

            
name="password"

            
className="form-control"

            
value={user.password}

            
onChange={handleChange}
            required
          />
        </div>

        {/* New fields */}
        <div
 
className="form-group">

          
<label>First Name</label>

          
<input

            
type="text"

            
name="firstName"

            
className="form-control"

            
value={user.firstName}

            
onChange={handleChange}

            
required
          />

        
</div>

        
<div
 
className="form-group">

          
<label>Last Name</label>

          
<input

            
type="text"

            
name="lastName"

            
className="form-control"

            
value={user.lastName}

            
onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>City</label>
          <input
            type="text"
            name="city"
            className="form-control"
            value={user.city}
            onChange={handleChange}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Register
        </button>
      </form>
    </div>
  );
};

export default Register;