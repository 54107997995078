import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import '../assets/header.css';
import ReactGA from 'react-ga';
import { useAuth } from '../AuthContext';
import CryptoJS from 'crypto-js';

ReactGA.initialize("G-NMF03FZN0E");

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('encryptedUserId');
    setIsLoggedIn(false);
    navigate('/');
  };

  const checkLoginStatus = useCallback(() => {
    const userId = localStorage.getItem('userId');
    setIsLoggedIn(userId !== null);
  }, [setIsLoggedIn]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    checkLoginStatus();

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('refresh')) {
      checkLoginStatus();
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [checkLoginStatus]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <nav className="navbar">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Logo" height="30" />
          </Link>
          <div className="burger-menu" onClick={toggleMenu}>
            <div className="burger-bar"></div>
            <div className="burger-bar"></div>
            <div className="burger-bar"></div>
          </div>
          <div className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
            {isLoggedIn ? (
              <>
                <Link className="nav-link" to="/mybooks">My Books</Link>
                <Link className="nav-link" to="/addbook">Add Books</Link>
                <Link className="nav-link" to="/wishlist">Wishlist</Link>
                <Link className="nav-link" to="/requests">Requests</Link>
                <Link className="nav-link" to="/profile">Profile</Link>
                <button className="btn" onClick={handleLogout}>Logout</button>
              </>
            ) : (
              <>
                <Link className="btn" to="/register">Register</Link>
                <Link className="btn" to="/login">Login</Link>
              </>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
