import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form, Dropdown } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard'; // Import the CopyToClipboard package
import axios from 'axios';
import "./ChatApp.css"

const openaiAPIKey = process.env.REACT_APP_OPENAI_API_KEY;

const ChatApp = ({ isOpen, onClose, selectedBook }) => {
  const [characters, setCharacters] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isThinking, setIsThinking] = useState(false);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    // Fetch characters when the selected book changes
    const fetchCharacters = async () => {
      if (selectedBook) {
        try {
          const response = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
              model: "gpt-3.5-turbo",
              messages: [
                {
                  role: "user",
                  content: `send the names of characters from the book ${selectedBook.title} by ${selectedBook.author} in an array format for example ["Harry potter", "Hermionie"]`,
                },
              ],
              max_tokens: 1200,
              temperature: 0.7,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${openaiAPIKey}`,
              },
            }
          );
          const characterNames = JSON.parse(response.data.choices[0].message.content);
          setCharacters(characterNames);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchCharacters();
  }, [selectedBook]);

  useEffect(() => {
    // Clear messages when the selected book changes
    setMessages([]);
  }, [selectedBook]);

  const handleMessageSend = async (message) => {
    setMessages([...messages, { text: message, sender: 'user' }]);
    setIsThinking(true);

    const getChatReply = async () => {
      try {
        const bookTitle = selectedBook.title;
        const bookAuthor = selectedBook.author;
        const character = selectedCharacter;

        const apiMessages = [
          {
            role: "system",
            content: `You are ${character} from the ${bookTitle} by ${bookAuthor}, Don't break character at all costs `,
          },
          ...messages.map((message) => ({
            role: message.sender === "character" ? "assistant" : message.sender,
            content: message.text,
          })),
          {
            role: "user",
            content: message,
          },
        ];

        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-3.5-turbo",
            messages: apiMessages,
            max_tokens: 1200,
            temperature: 0.7,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${openaiAPIKey}`,
            },
          }
        );

        console.log(response.data.choices[0].message.content);
        setIsThinking(false);
        setMessages((prevMessages) => [...prevMessages, { text: response.data.choices[0].message.content, sender: 'character' }]);
      } catch (error) {
        console.log(error);
        setIsThinking(false);
      }
    };

    await getChatReply();
};


  const handleClose = () => {
    setIsThinking(false);
    setMessages([]);
    setCharacters([]);
    setSelectedCharacter("")
    onClose();
  };


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);


  const handleCopyResponse = (index) => {
    const userQuestion = messages[index - 1]?.text || '';
    const aiAnswer = messages[index]?.text || '';
    const messageToCopy = `User: ${userQuestion}\nAI: ${aiAnswer}\nGenerated with storychats.com - Talk to characters from your favorite books`;

    navigator.clipboard.writeText(messageToCopy).then(
      () => {
        console.log('Text copied to clipboard');
      },
      (err) => {
        console.error('Could not copy text: ', err);
      }
    );
  };


  return (
    <Modal show={isOpen} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Chat with characters from {selectedBook.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className = "m-1">
        <Dropdown onSelect={(key) => setSelectedCharacter(characters[key]) || setMessages([])} >
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
           
          {selectedCharacter || 'Select a character'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {characters.map((character, index) => (
              <Dropdown.Item key={index} eventKey={index}>
                {character}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        </div>

        <div className="messages-container" ref={chatContainerRef}>
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender === 'user' ? 'user' : 'character'}`}>
              {message.text}
              {message.sender === 'character' && (
                  <CopyToClipboard
                    text={`User: ${messages[index - 1]?.text}\n${selectedCharacter}: ${message.text}\nGenerated with storychats.com - Talk to characters from your favourite books`}
                    onCopy={() => console.log('Text copied to clipboard')}
                  >
                    <button className="copy-response-btn">
                      Copy
                    </button>
                  </CopyToClipboard>
                )}
            </div>
          ))}
          {isThinking && (
            <div className="chat-message character">
              <span>Thinking...</span>
            </div>
          )}
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            const message = e.target.elements.message.value;
            if (message.trim()) {
              handleMessageSend(message);
              e.target.elements.message.value = '';
            }
          }}
        >
          <Form.Group className="d-flex">
            <Form.Control
              type="text"
              placeholder="Ask a question..."
              name="message"
            />
            <Button type="submit" className="ml-2">
              Send
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChatApp;
