// components/MyBooks.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Alert } from 'react-bootstrap';

import axios from "axios";
import { decryptUserId } from "../utils/encryption";
import addToWishlist from "./AddToWishlist";
import addToWishlistico from "./wishlist.png";
import { toast } from 'react-toastify'; // Import toast
import "./MyBooks.css"; // Ensure this CSS file exists and is updated as per below

const MyBooks = () => {
  const { encryptedUserId } = useParams();
  const [userBooks, setUserBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
 
  const [userDetails, setUserDetails] = useState();
  const [ IsUserDetailsFetched, setIsUserDetailsFetched] = useState();
  const pageuserId = decryptUserId(encryptedUserId);

 

  const LocalApiUrl = process.env.REACT_APP_API_BASE_URL;
 


  


  useEffect(() => {
    const fetchUserBooks = async () => {
      const userId = decryptUserId(encryptedUserId);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/books/${pageuserId}`);
        const sortedBooks = response.data.books
          .sort((a, b) => new Date(b.addedOn) - new Date(a.addedOn))
          .filter((book) => {
            return (
              (book.wishlist === false && book.exclusion === false) ||
              (typeof book.wishlist === 'undefined' && typeof book.exclusion === 'undefined')
            );
          })

        setUserBooks(sortedBooks);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching books for user:", userId, error);
        setIsLoading(false);
      }
    };

    const getUserDetails = async () => {
      if (!IsUserDetailsFetched)
      {
        try {
        const response = await axios.get(`${LocalApiUrl}/user/details`, {
          params: { email: pageuserId }
        });
        setUserDetails({
          firstName: response.data.firstName,
          lastName: response.data.lastName
        });
        setIsUserDetailsFetched(true);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    }
    };

    if (encryptedUserId) {
      fetchUserBooks();
    }
    getUserDetails();
  }, [encryptedUserId], [IsUserDetailsFetched]);

  
  

  const handleAddToWishlist = (book) => {
    if (localStorage.getItem("userId")) {
      addToWishlist(book)
      .then(() => {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000); // Auto-hide after 3 seconds
      })
      .catch((error) => {
        toast.error("Error adding book to wishlist:", error.message);
      });
  } else {
    toast.info("You need to create an account to add items to wishlist.");
    setTimeout(() => navigate('/register'), 7000); // Navigate after 5 seconds
  }
  };

  if (isLoading) {
    return <div>Loading books...</div>;
  }

  if (!userBooks.length) {
    return <div>No books found for this user.</div>;
  }

  return (
    <div className="mybooks-container">
      <h2>{userDetails.firstName} {userDetails.lastName}'s Books</h2>
      <div className="books-grid">
        {userBooks.map((book, index) => (
          <div key={index} className="book-card">
             <img 
                                            src={addToWishlistico} 
                                            className="wishlist" 
                                            alt="Add to wishlist" 
                                            onClick={() => handleAddToWishlist(book)}
                                            title="Add To Wishlist"
                                            />
            <img src={book.imageLink} alt={book.title} className="book-image" />
            
            <div className="book-info">
              <h3>{book.title}</h3>
              <p>By {book.author}</p>
             
             
            </div>
          </div>
        ))}
      </div>
      {showAlert && (
  <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
    Book added to wishlist!
  </Alert>
)}
    </div>
  );
};

export default MyBooks;
