// utils/encryption.js

import CryptoJS from 'crypto-js';
const base64 = require('js-base64').Base64;
export const decryptUserId = (encryptedId) => {
    // Your decryption logic here
    // For now, let's just return the encryptedId assuming it's the userId
    // In a real scenario, you would have decryption logic based on your encryption method

    // Decryption

    const decodedString = base64.decode(encryptedId);
   

  const bytes = CryptoJS.AES.decrypt(decodedString, process.env.REACT_APP_SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

  