import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Register from "./components/Register";
import Login from "./components/Login";
import Header from "./components/Header";
import HomePage from "./components/HomePage";
import AddBook from "./components/AddBook";
import MyBooks from "./components/my-books";
import Community from "./components/community";
import Requests from "./components/borrowRequest"
import WishList from "./components/wishlist";
import{ Analytics } from '@vercel/analytics/react'
import { AuthProvider } from './AuthContext'; // Add this import
import Profile from "./components/profile";
;

function App() {
  return (
    <Router>
      <AuthProvider>
       <Header />
       < Analytics />
      <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/addbook" element={<AddBook />} />
        <Route path="/wishlist" element={<WishList />} />
        <Route path="/mybooks/:encryptedUserId" element={<MyBooks />} />
        <Route path="/profile" element={<Profile />} />
        <Route path ="/requests" element={<Requests />} />
        <Route path="/community/:communityId" element={<Community />} />
        
      </Routes>
          {/* Add other routes here */}
        
      </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
