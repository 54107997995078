import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
const base64 = require('js-base64').Base64;

const LocalApiUrl = process.env.REACT_APP_API_BASE_URL;

const Login = () => {
  const Navigate = useNavigate();
  const [user, setUser] = useState({ email: "", password: "" });
  const [error, setError] = useState(null);

  const encryptUserId = (userId) => {
    const secret_key = process.env.REACT_APP_SECRET_KEY || "Yq3t6v9y";
    if (!secret_key) {
      console.error("Secret key is not set.");
      return null;
    }
    try {
      

      const encrypted = CryptoJS.AES.encrypt(userId.toString(), secret_key).toString();
      const urlSafeBase64String = base64.encode(encrypted, true); // Second argument enables URL-safe encoding
      return urlSafeBase64String
      
    } catch (err) {
      console.error("Error encrypting user ID:", err);
      return null;
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${LocalApiUrl}/user/login`, user);
      if (response.data && response.data.userId) {
        const encryptedUserId = encryptUserId(response.data.userId);
        if (encryptedUserId) {
          localStorage.setItem("authToken", response.data.token);
          localStorage.setItem("userId", response.data.userId);
          localStorage.setItem("encryptedUserId", encryptedUserId);
          localStorage.setItem("firstName", response.data.firstName);
          localStorage.setItem("lastName", response.data.lastName);
          Navigate("/addbook?refresh=true");
        } else {
          setError("Failed to encrypt user ID.");
        }
      } else {
        setError("Invalid response from server.");
      }
    } catch (err) {
      console.error(err);
      setError("Login failed. Please try again.");
      if (err.response) {
        switch (err.response.status) {
          case 400:
            setError("Invalid email or password.");
            break;
          case 401:
            setError("Your account is not activated. Please check your email for the activation link.");
            break;
          default:
            setError("Something went wrong. Please try again later.");
            break;
        }
      } else {
        setError("Unable to connect to the server. Please check your internet connection and try again.");
      }
    }
  };

  return (
    <div className="container w-50 a-center">
      <h2>Login</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            className="form-control"
            value={user.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            name="password"
            className="form-control"
            value={user.password}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
