    import React, { useState, useEffect } from "react";
    import { Link } from "react-router-dom";
    // import { Configuration, OpenAIApi } from "openai";
    import Modal from 'react-bootstrap/Modal';
    import Button from 'react-bootstrap/Button';
    import { toBlob } from 'html-to-image';
    import ChatApp from './ChatApp';

    import { saveAs } from 'file-saver';
    import CryptoJS from 'crypto-js';

 


    import addToLibrary from "../assets/add-to-library.png";

    import addToWishlistico from "./wishlist.png";
    import addToWishlist from "./AddToWishlist";
    import AddtoExclusion from "./AddtoExclusion";
    import dislike from "./dislike.png";
    import share from "./share.png"


    import axios from "axios";
    import "./AddBook.css"

        const openaiAPIKey = process.env.REACT_APP_OPENAI_API_KEY;
        const LocalApiUrl = process.env.REACT_APP_API_BASE_URL;

    function AddBook() {
        const [bookTitle, setBookTitle] = useState("");
        const [bookData, setBookData] = useState(null);

        const [userBooks, setUserBooks] = useState([]);
        const [wishlist, setwishlist] = useState([]);
        const [exclusionlist, setExclusionlist] = useState([]);
        const [bookRecommendations, setBookRecommendations] = useState([]);
        const [hasRecommendationsUpdated, setHasRecommendationsUpdated] = useState(false);
        const [removedRecommendations, setRemovedRecommendations] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        const [showModal, setShowModal] = useState(false);
        const [currentBook, setCurrentBook] = useState(null);
        const [note, setNote] = useState('');
        const [isChatAppOpen, setIsChatAppOpen] = useState(false);
        const [selectedBook, setSelectedBook] = useState(null);
        const [messages, setMessages] = useState([]);
        const [shuffledBookTitles, setShuffledBookTitles] = useState([]);



        useEffect(() => {
            fetchUserBooks();
        }, []);

        useEffect(() => {
            fetchWishlist();
        }, []);

        useEffect(() => {
            fetchExclusionlist();
        }, []);

        useEffect(() => {
            if (userBooks.length > 0 && !hasRecommendationsUpdated) {
                getBookRecommendations();
            }
        }, [userBooks]);

        const CryptoJS = require("crypto-js");
        const secretKey = 'your-secret-key'; // This should be a secure key stored safely
    
        const encryptUserId = (userId) => {
          return CryptoJS.AES.encrypt(userId, secretKey).toString();
        };
        
            // Decryption
        
    
        const encryptedId = encryptUserId(localStorage.getItem("userId"));
        const publicUrl = `${window.location.origin}/public-books?user=${encryptedId}`;
    
    
        
       

        useEffect(() => {
            if (hasRecommendationsUpdated) {
              setTimeout(() => {
                setHasRecommendationsUpdated(false);
              }, 70000);
            }
          }, [hasRecommendationsUpdated]);


          const fetchExclusionlist = async () => {
            const userId = localStorage.getItem("userId");
            try {
                const response = await axios.get(`${LocalApiUrl}/books/${userId}`);
                const filteredBooks = response.data.books.filter(book => book.exclusion);
                const sortedBooks = filteredBooks.sort((a, b) => new Date(b.addedOn) - new Date(a.addedOn));
                setExclusionlist(sortedBooks);

                // Log to check the sorted books
            } catch (error) {
                console.error('Error fetching exclusion list:', error);
            }
        };

        const fetchWishlist = async () => {
            const userId = localStorage.getItem("userId");
            const response = await axios.get(`${LocalApiUrl}/books/${userId}`);
            const filteredBooks = response.data.books.filter(
            (book) => book.wishlist === true
            );
            const sortedBooks = filteredBooks.sort(
            (a, b) => new Date(b.addedOn) - new Date(a.addedOn)
            );
            setwishlist(sortedBooks);
        };
        // function to handle removing recommendations
        const handleRemoveRecommendation = async (book) => {
            setBookRecommendations(bookRecommendations.filter((b) => b.title !== book.title));
            const updatedRemovedRecommendations = [...removedRecommendations, book];
            setRemovedRecommendations(updatedRemovedRecommendations);
          //  await saveRemovedRecommendation(book);
          await AddtoExclusion (book);
        };

        const getBookRecommendations = async () => {
            setIsLoading(true);
            try {
                const bookTitles = userBooks.map((book) => book.title).join(", ");
                const shuffledTitles = pickRandomBooks(bookTitles);
                setShuffledBookTitles(shuffledTitles);
                const wishlistTitles = wishlist.map((book) => book.title).join(", ");
                const ExclusionTitles = exclusionlist.map((book) => book.title).join(", ");
                console.log(`Eclusion titles are here ${ExclusionTitles}`);
               
                 const response = await axios.post(
                    "https://api.openai.com/v1/chat/completions",
                    {
                        model: "gpt-3.5-turbo-1106",
                        messages: [
                            {
                                role:"system",
                                content:"you are the worlds most sophisticated Book Recommendation engine. Your recommendations are unique and intriguing. You never recommend a book user has already read or added in exclusion list."
                            },
                            {
                                role: "user",
                                content: `Recommend me 3 books, each from a different Genre. This is my reading history, ${shuffledTitles}, \n based on my reading history recommend my 3 books, each of a different Genre, which are not in the above books. \n Lean towards discovery of new books. \n It is extremely important that the recommended books are NOT among these: ${ExclusionTitles}, \n for the 3 books, give me a JSON with their title, Author, ISBN, release_year, genre, because_you_read and provide a 20 word summary for each as string format. Just give Book1, Book2 and Book3, Don't say Recommendations.`,
                                
                            },
                        ],
                        max_tokens: 1500,
                        temperature: 0.9,
                        response_format: {"type":"json_object"}
                    
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${openaiAPIKey}`,
                        },
                    }
                );

                const recommendationsText = response.data.choices[0].message.content;
                console.log("Recommended Text",recommendationsText);
                // console.log("Recommendations text: ", recommendationsText);
                const recommendationsObj = JSON.parse(recommendationsText);

                        // Fetch book images
                for (const key in recommendationsObj) {
                    const book = recommendationsObj[key];
                    const imageLink = await fetchBookThumbnail(book.ISBN);
                    book.imageLink = imageLink;
                }
                
                setBookRecommendations(Object.values(recommendationsObj));
                setHasRecommendationsUpdated(true);
                setIsLoading(false);
            } catch (error) {
                console.error("Error getting book recommendations:", error);
            }
        };

        const fetchUserBooks = async () => {
            const userId = localStorage.getItem("userId");
            const response = await axios.get(`${LocalApiUrl}/books/${userId}`);
            const filteredBooks = response.data.books.filter(book => book.wishlist !== true && book.exclusion !== true );
            const sortedBooks = filteredBooks.sort(
                (a, b) => new Date(b.addedOn) - new Date(a.addedOn)
            );
            
            setUserBooks(sortedBooks);
        };

        const handleChange = (e) => {
            setBookTitle(e.target.value);
        };

        const handleSubmit = async (e) => {
            e.preventDefault();
            const bookDetails = await getBookDetails(bookTitle);
            await saveBookDetails(bookDetails);
            setBookData(bookDetails);
        };

        const getBookDetails = async (title) => {
            try {
                const response = await axios.post(
                    "https://api.openai.com/v1/chat/completions",
                    {
                        model: "gpt-3.5-turbo",
                        messages: [
                            {
                                role: "user",
                                content: `Please provide the ISBN, title, author and release year in JSON format with all variables as String for the book titled ${title}`,
                            },
                        ],
                        max_tokens: 100,
                        temperature: 0.7,
                        
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${openaiAPIKey}`,
                        },
                    }
                );

                const message = response.data.choices[0].message.content;
                const bookDetails = JSON.parse(message);
               

                return bookDetails;
            } catch (error) {
                console.error("Error getting book details:", error);
            }
        };

        // const fetchBookThumbnail = async (ISBN) => {
        //     try {
        //     const ISBN1 = ISBN.replace(/-/g, "");
        //     const response = await axios.get(
        //         `https://www.googleapis.com/books/v1/volumes?q=isbn:${ISBN1}`
        //     );
        
        //     if (response.data.items && response.data.items.length > 0) {
        //         const book = response.data.items[0].volumeInfo;
        //         if (book.imageLinks && book.imageLinks.smallThumbnail) {
        //         return book.imageLinks.smallThumbnail;
        //         } else {
        //             console.error("Book cover image not found");
        //             return null;
        //         }
        //         } else {
        //         console.error("No books found for ISBN " + ISBN1);
        //         return null;
        //         }
        //     } catch (error) {
        //         console.error(error);
        //         return null;
        //     }
        //     };

            const fetchBookThumbnail = async (ISBN) => {
                try {
                  const ISBN1 = ISBN.replace(/-/g, "");
                  const url = `https://covers.openlibrary.org/b/isbn/${ISBN1}-M.jpg`;
                  const response = await axios.get(url, { responseType: 'blob' });
              
                  if (response.status === 200) {
                    return URL.createObjectURL(response.data);
                  } else {
                    console.error("Cover image not found for ISBN " + ISBN1);
                    return null;
                  }
                } catch (error) {
                  console.error("Error fetching book cover image:", error);
                  return null;
                }
              };
              

             // Function to shuffle an array
  const shuffleArray = (array) => {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
    }
    return shuffled;
  }

  // Function to shuffle book titles
  const pickRandomBooks = (titlesString) => {
    const bookTitlesArray = titlesString.split(", "); // Split string into array
    const shuffledTitlesArray = shuffleArray(bookTitlesArray);
    return shuffledTitlesArray.join(", "); // Join array back into string
  }
              
                
              

              
        const saveBookDetails = async (details) => {
            try {
                //removing dashes from the ISBN
                const ISBN1 = details.ISBN.replace(/-/g, "");
                const response1 = await axios.get(
                    `https://www.googleapis.com/books/v1/volumes?q=isbn:${ISBN1}`
                );

                if (response1.data.items && response1.data.items.length > 0) {
                    const book = response1.data.items[0].volumeInfo;
                    if (book.imageLinks && book.imageLinks.thumbnail) {
                        details.imageLink = response1.data.items[0].volumeInfo.imageLinks.smallThumbnail;
                        console.log("Fetched image link:", details.imageLink);
                    } else {
                        console.error("Book cover image not found");
                    }
                } else {
                    console.error("No books found for ISBN " + ISBN1);
                }


                const userId = localStorage.getItem("userId");

                details.addedOn = Date.now;
                details.wishlist = 0;
                details.exclusion = 0;

                
                const response = await axios.post(`${LocalApiUrl}/books/add`, {
                    userId,
                    book: details,
                });

                if (response.data.success) {
                    console.log("Book saved successfully.");
                } else {
                    console.log("Error saving book.");
                }

                fetchUserBooks();
            } catch (error) {
                console.error(error);
            }
        };

        const handleAddBook = async (book) => {
            await saveBookDetails(book);
        };

        const handleWishlist = async (book) => {
            await addToWishlist (book);
        }

        const handlesetChat = (book) => {


        }
        const handleOpenModal = (book) => {
            setCurrentBook(book);
            setNote(book.note);
            setShowModal(true);
          };
          
          const handleCloseModal = () => {
            setShowModal(false);
          };
          
          const updateBookNote = async (userId, title, note) => {
            const response = await fetch(`${LocalApiUrl}/books/update/${userId}/${title}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ note }),
            });
          
            const data = await response.json();
            
            return data;
          };
          

          const handleSaveNote = async () => {
            
            if (currentBook) {
                try {
                  const data = await updateBookNote(currentBook.userId, currentBook.title, note);
                  if (data.success) {
                    // Update the book in the userBooks array with the new note
                    const updatedBooks = userBooks.map((book) =>
                      book.title === currentBook.title && book.userId === currentBook.userId
                        ? { ...book, note: data.book.note }
                        : book
                    );
                    setUserBooks(updatedBooks);
                    console.log("Note is: ",data.book.note)
                  } else {
                    console.error(data.message);
                  }
                } catch (error) {
                  console.error('Error updating note:', error);
                }
              }
        
            setShowModal(false);
          };

        const handleDeleteBook = async (title) => {
            try {
                const userId = localStorage.getItem("userId");
                const response = await axios.delete(
                    `${LocalApiUrl}/books/delete/${userId}/${title}`
                );

                if (response.data.success) {
                    console.log("Book deleted successfully.");
                } else {
                    console.log("Error deleting book.");
                }

                
                fetchUserBooks();
            } catch (error) {
                console.error(error);
            }
        };

        const handleShareBook = (book) => {
            const cardId = `book-card-${book.ISBN}`;
            const node = document.getElementById(cardId);
        
            toBlob(node)
                .then((blob) => {
                    saveAs(blob, `${book.title}-card.png`);
                })
                .catch((error) => {
                    console.error('Error generating image:', error);
                });
        };
        
        const handleChatClick = (book) => {
            setSelectedBook(book);
            setIsChatAppOpen(true);
          };







        return (
            <div className="container">
                <h2 className= "m-3 p-3">Add a Book</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Book Title:</label>
                        <input
                            type="text"
                            name="bookTitle"
                            className="form-control"
                            value={bookTitle}
                            onChange={handleChange}
                            required />
                    </div>
                    <button type="submit" className="btn bg-black text-light mt-1 a-center">
                        Submit
                    </button>
                </form>

                <div className="user-books">
                    <h3>Your Books:
                        <span className="badge bg-black m-2">
                        Total: {userBooks.length}
                        </span>
                        <span>
                        <Link className="btn mr-2 a-r bg-brown" to="/wishlist">
                        ❤️ Wishlist
                </Link>
                        </span>
                    </h3>
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {userBooks.map((book, index) => (
                            <div key={index} className="col">
                                <div id={`book-card-${book.ISBN}`} className="card h-100">
                                    <button
                                        className="btn btn-danger btn-sm delete-button"
                                        onClick={() => handleDeleteBook(book.title)}
                                    >
                                        Delete
                                    </button>
                                    
                                   

                                    <div className="card-body">
                                      
                                        <img className="m-2" src={book.imageLink} alt="Book Thumbnail"/>
                                        
                                      
                                        <h5 className="card-title">{book.title}</h5>

                                        <p className="card-text">
                                            <strong>Author:</strong> {book.author}
                                        </p>
                                        <p className="card-text">
                                            <strong>ISBN:</strong> {book.ISBN} {book.isbn}
                                        </p>
                                        <p className="card-text">
                                            <strong>Release Year:</strong> {book.release_year}{" "}
                                            {book.releaseDate}
                                        </p>

                                        <p className="card-text">
                                        <strong></strong> {book.note}
                                         </p>
                                        
                                        <a
                                        href="#!"
                                        className="card-link"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenModal(book);

                                        
                                        
                                        }}
                                        >
                                        
                                             {book.note && book.note.trim().length > 0 ? 'Update Note' : 'Add Note'}
                                        </a>
                                        <button
                                            className="btn btn-secondary ml-2 btn-sm"
                                            alt="chat button"
                                            onClick={() => handleChatClick(book)}
                                        >
                                            Chat
                                        </button>
                                        
                                        </div>
                                        
                                </div>
                            </div>
                        ))}
                            {selectedBook && (
                                            <ChatApp
                                            isOpen={isChatAppOpen}
                                            onClose={() => {
                                                setIsChatAppOpen(false);
                                                setMessages([]); 
                                                
                                            }}
                                            selectedBook={selectedBook}
                                            />
                                        )} 
                        
                              {/* Add Modal for note input */}
                            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                                <Modal.Header closeButton>
                                <Modal.Title>Add Note</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                {currentBook && (
                                    <>

                                    <h5>{currentBook.title}</h5>
                                    <p>
                                        <strong>Author:</strong> {currentBook.author}
                                    </p>
                                    {/* Add other book details if needed */}
                                    <hr />
                                    <textarea
                                        className="form-control"
                                        rows="5"
                                        placeholder="Enter your note here..."
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                    ></textarea>
                                    </>
                                )}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseModal}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleSaveNote}>
                                    Save Note
                                </Button>
                                </Modal.Footer>
                            </Modal>

                         

                    </div>
                </div>
                <div className = "emptyspace">
                    <p> .</p>   
                </div>
                                        
                <div className="recommendations-footer">
                <div className="container">
                    <h3 className="rbookstitle">Recommended Books Based on Your Reading History</h3>

                    {isLoading ? (
                        <div className="text-center">
                        <div className="loading-spinner"></div>
                        </div>
                    ) :
                        (
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {bookRecommendations.map((book, index) => (
                            <div key={index} className="col">
                                <div className="card h-100">
                                    <div className="card-body1">
                                    <div className = "book-image1"> 
                                        <img className="m-2" src={book.imageLink} alt="Book Thumbnail"/>
                                    </div>
                                    <div className = "book-content">
                                        <h5 className="card-title">{book.title}</h5>
                                        <p className="card-text">
                                            <strong>By:</strong> {book.author}
                                        </p>
                                       
                                        <p className="card-text">{book.summary}</p>
                                                    
                                            <img 
                                            src={addToLibrary} 
                                            className="btn addtolib" 
                                            alt="Add to Library" 
                                            onClick={() => handleAddBook(book)}
                                            title="Add To Library"
                                            />

                                            <img 
                                            src={addToWishlistico} 
                                            className="btn addtolib" 
                                            alt="Add to wishlist" 
                                            onClick={() => handleWishlist(book)}
                                            title="Add To Wishlist"
                                            />

                                            <img 
                                            src={dislike} 
                                            className="btn addtolib" 
                                            alt="Dislike" 
                                            onClick={() => handleRemoveRecommendation(book)}
                                            title="Add To Exclusion"
                                            />



                                        
                                    </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                     )}
                </div>
                <div className="madeby">
                    Made with ❤️ by <a href="https://twitter.com/asifkabeer" target="_blank" rel="noopener noreferrer">Asif</a>

                </div>
                </div>
            </div>
        );
    }
    export default AddBook;
