import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

const BorrowRequest = () => {
  const [requestsReceived, setRequestsReceived] = useState([]);
  const [requestsMade, setRequestsMade] = useState([]);
  const LocalApiUrl = process.env.REACT_APP_API_BASE_URL;
  const [rejectedRequests, setRejectedRequests] = useState({});
  
  const userId = localStorage.getItem("userId"); // Assuming you store userId in localStorage
 



  const fetchRequests = async () => {
    try {
      const response = await axios.get(`${LocalApiUrl}/ledger/user-requests/${userId}`);
      setRequestsReceived(response.data.requestsReceived);
      setRequestsMade(response.data.requestsMade);
    } catch (error) {
      console.error('Error fetching borrow requests:', error);
    }
  };

  useEffect(() => {
    // Fetch borrow requests
    

    fetchRequests();
  }, [userId, LocalApiUrl]);

  const handleRequestUpdate = async (request, newStatus) => {
    try {
      // Update the status in the Ledger
      let response = await axios.put(`${LocalApiUrl}/ledger/update-borrow-request/${request._id}`, { newStatus });
      console.log('Request updated in Ledger:', response.data);
  
      // Define the new status for the book in the Community based on the new ledger status
      let newBookStatus;
      if (newStatus === 'approved') {
        newBookStatus = 'unavailable';
      } else if (newStatus === 'returned') {
        newBookStatus = 'available';
      }
     else if (newStatus === 'rejected') {
        setRejectedRequests(prev => ({ ...prev, [request._id]: true }));
    }
      
    
  
      // If the book is being approved for borrowing or returned, update the book status in the Community
      if (newBookStatus) {
        response = await axios.put(`${LocalApiUrl}/community/update-book-status/${request.communityId}`, {
          bookId: request.bookId,
          newBookStatus: newBookStatus
        });

        console.log('Book status updated in Community:', response.data);
        fetchRequests();
      }
  
      // Re-fetch requests to update the UI
      // fetchRequests();
    } catch (error) {
      console.error('Error updating borrow request:', error);
    }
  };

  return (
    <div className="container my-4">
      <h2 className="mb-3">Requests Received</h2>
      {requestsReceived.length > 0 ? (
      <div className="row">
        {requestsReceived.map(request => (
          <div key={request._id} className="col-lg-4 col-md-6 mb-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{request.title}</h5>
                <p className="card-text">Author: {request.author}</p>
                <p className="card-text">Status: {request.status}</p>
                <p className="card-text"> Requested By: {request.borrowerId}</p>
                {request.status === 'requested' && (
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <button
              onClick={() => handleRequestUpdate(request, 'approved')}
              className="btn btn-success me-md-2"
            >
              Approve
            </button>
            <button
              onClick={() => handleRequestUpdate(request, 'rejected')}
              className="btn btn-danger"
            >
              Reject
            </button>
          </div>
        )}
        {request.status === 'approved' && (
          <div className="d-grid gap-2">
            <button
              onClick={() => handleRequestUpdate(request, 'returned')}
              className="btn btn-info"
            >
              Mark as Returned
            </button>
          </div>
        )}
         {rejectedRequests[request._id] && (
          <p>Request Rejected</p>
        )}
              </div>
            </div>
          </div>
        ))}
      </div>

) : (
    <div >
       <h5 className="mb-5 mt-5">  You have not received any requests. </h5>
    </div>
  )}

      <h2 className="mb-3">My Requests</h2>
      {requestsMade.length > 0 ? (
      <div className="row">
        {requestsMade.map(request => (
          <div key={request._id} className="col-lg-4 col-md-6 mb-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{request.title}</h5>
                <p className="card-text">Author: {request.author}</p>
                <p className="card-text">Status: {request.status}</p>
                
                {/* Optionally, you can add actions for requests made by the user */}
              </div>
            </div>
          </div>
        ))}
      </div>
       ) : (
        <div >

          <h3 className="mb-5 mt-5">You have not made any requests.</h3>
        </div>
      )}
    </div>
  );
};

export default BorrowRequest;
