import axios from "axios";
import { toast } from 'react-toastify'; // Import toast


const LocalApiUrl = process.env.REACT_APP_API_BASE_URL;

const addToWishlist = async (details, showNotifications) => {
  // ... original addToWishlist function code ...
  
    try {
        //removing dashes from the ISBN
        const ISBN1 = details.ISBN.replace(/-/g, "");
        const response1 = await axios.get(
            `https://www.googleapis.com/books/v1/volumes?q=isbn:${ISBN1}`
        );

        if (response1.data.items && response1.data.items.length > 0) {
            const book = response1.data.items[0].volumeInfo;
            if (book.imageLinks && book.imageLinks.thumbnail) {
                details.imageLink = response1.data.items[0].volumeInfo.imageLinks.smallThumbnail;
               
            } else {
                console.error("Book cover image not found");
            }
        } else {
            console.error("No books found for ISBN " + ISBN1);
        }


        const userId = localStorage.getItem("userId");

        details.addedOn = Date.now;
        details.wishlist = 1;

        
        const response = await axios.post(`${LocalApiUrl}/books/add`, {
            userId,
            book: details,
        });

        if (response.data.success) {
            if (response.data.success) {
                // Show popup notification
               
                console.log("Book saved to wishlist successfully.");
            } else {
                console.log("Error saving book to wishlist.");
            }
        }
    } catch (error) {
        console.error(error);
    }
}

export default addToWishlist;