import React, { useState, useEffect } from "react";
import axios from "axios";

const LocalApiUrl = process.env.REACT_APP_API_BASE_URL;
const openaiAPIKey = process.env.REACT_APP_OPENAI_API_KEY;



function WishList() {
  const [userBooks, setUserBooks] = useState([]);
  const [wishlist, setwishlist] = useState([]);
  const [bookData, setBookData] = useState(null);
  const [bookTitle, setBookTitle] = useState("");

  useEffect(() => {
    fetchWishlist();
  }, []);

  const fetchWishlist = async () => {
    const userId = localStorage.getItem("userId");
    const response = await axios.get(`${LocalApiUrl}/books/${userId}`);
    const filteredBooks = response.data.books.filter(
      (book) => book.wishlist === true
    );
    const sortedBooks = filteredBooks.sort(
      (a, b) => new Date(b.addedOn) - new Date(a.addedOn)
    );
    setUserBooks(sortedBooks);
  };

  // Add this function to handle book deletion
  const handleDeleteBook = async (title) => {
    // Your logic for deleting a book from the wishlist
    try {
        const userId = localStorage.getItem("userId");
        const response = await axios.delete(
            `${LocalApiUrl}/books/delete/${userId}/${title}`
        );

        if (response.data.success) {
            console.log("Book deleted successfully.");
        } else {
            console.log("Error deleting book.");
        }

    } catch (error) {
        console.error(error);
    }

    const getBookDetails = async (title) => {
        try {
            const response = await axios.post(
                "https://api.openai.com/v1/chat/completions",
                {
                    model: "gpt-3.5-turbo",
                    messages: [
                        {
                            role: "user",
                            content: `Please provide the ISBN, title, author and release year in JSON format with all variables as String for the book titled ${title}`,
                        },
                    ],
                    max_tokens: 100,
                    temperature: 0.7,
                    
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${openaiAPIKey}`,
                    },
                }
            );

            const message = response.data.choices[0].message.content;
            const bookDetails = JSON.parse(message);
           

            return bookDetails;
        } catch (error) {
            console.error("Error getting book details:", error);
        }
    };



    /* const handleSubmit = async (e) => {
        e.preventDefault();
        const bookDetails = await getBookDetails(bookTitle);
        await saveBookDetails(bookDetails);
        setBookData(bookDetails);
    };

    const handleChange = (e) => {
        setBookTitle(e.target.value);
    };
    */
    };


    

  return (
            /*
            <>
            
            <div>
          <h2 className="m-3 p-3">Add a Book</h2>
          <form onSubmit={handleSubmit}>
              <div className="form-group">
                  <label>Book Title:</label>
                  <input
                      type="text"
                      name="bookTitle"
                      className="form-control"
                      value={bookTitle}
                        onChange={handleChange}
                      value={bookTitle}
                      
                      required />
              </div>
              <button type="submit" className="btn bg-black text-light mt-1 a-center">
                  Submit
              </button>
          </form>
      </div>
      
      */
     <>
      <div className="user-books">
              <h3>
                  Your Wishlist
                  <span className="badge bg-black m-2">Total: {userBooks.length}</span>
              </h3>
              <div className="row row-cols-1 row-cols-md-3 g-4">
                  {userBooks.map((book, index) => (
                      <div key={index} className="col">
                          <div className="card h-100">
                              <button
                                  className="btn btn-danger btn-sm delete-button"
                                  onClick={() => handleDeleteBook(book.title)}
                              >
                                  Delete
                              </button>
                              <div className="card-body">
                                  <img className="m-2" src={book.imageLink} alt={book.title} />
                                  <h5 className="card-title">{book.title}</h5>

                                  <p className="card-text">
                                      <strong>Author:</strong> {book.author}
                                  </p>
                                  <p className="card-text">
                                      <strong>ISBN:</strong> {book.ISBN} {book.isbn}
                                  </p>
                                  <p className="card-text">
                                      <strong>Release Year:</strong> {book.release_year}{" "}
                                      {book.releaseDate}
                                  </p>
                              </div>
                          </div>
                      </div>
                  ))}
              </div>
          </div>
          >
          </>
  );
};

export default WishList;
