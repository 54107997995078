import axios from "axios";

const LocalApiUrl = process.env.REACT_APP_API_BASE_URL;

const AddtoExclusion = async (details) => {
  // ... original addToWishlist function code ...
  
    try {
      

        const userId = localStorage.getItem("userId");

        details.addedOn = Date.now;
        details.exclusion = 1;

        
        const response = await axios.post(`${LocalApiUrl}/books/add`, {
            userId,
            book: details,
        });

        if (response.data.success) {
            console.log("Book added to exclusion");
        } else {
            console.log("Error adding book to exclusion");
        }

       
    } catch (error) {
        console.error(error);
    }
};


export default AddtoExclusion;