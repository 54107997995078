import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // UUID for generating bookId
import './community.css';
import { Button } from 'react-bootstrap';
import { decryptUserId } from "../utils/encryption";
import './MyBooks.css';
import CryptoJS from "crypto-js";
const base64 = require('js-base64').Base64;


const Community = () => {
  const [books, setBooks] = useState([]);
  const [newBookData, setNewBookData] = useState({
    title: '',
    author: '',
    lending: false,
    read: false,
  });
  const [communityName, setcommunityName] = useState();
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({ firstName: '', lastName: '' });
  const [isUserDetailsFetched, setIsUserDetailsFetched] = useState(false);
  
  const { communityId } = useParams();
  console.log("Received Community ID:", communityId); // Check the format of the communityId
  const LocalApiUrl = process.env.REACT_APP_API_BASE_URL;
  const userId = localStorage.getItem("userId");
  
  const handleBorrowBook = async (book) => {
    const confirmBorrow = window.confirm(`This would send a borrow request to ${book.firstName} ${book.lastName} to approve. You also agree to share your email address with the Book Owner. Do you want to request to borrow ${book.title}?`);
  
    if (confirmBorrow) {
      // Constructing the data for the API call
      const borrowRequestData = {
        communityId: communityId, // Assuming you have the community ID available
        bookId: book.bookId,
        title: book.title,
        author: book.author,
        addedBy: book.addedBy,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        
        lending: book.lending,
        read: book.read,
        upvotes: book.upvotes,
        ownerId: book.addedBy, // Assuming the 'addedBy' field contains the owner's ID
        borrowerId: userId, // The ID of the user making the request
        borrowNote: "Request to borrow this book" // Or any other note you'd like to add
      };
  
      try {
        // Making the API call to create a new borrow request
        const response = await axios.post(`${LocalApiUrl}/ledger/create-borrow-request`, borrowRequestData);
        if (response.data) {
          console.log('Borrow request created successfully:', response.data);
          // Additional actions upon successful request creation (e.g., updating state or UI)
        }
      } catch (error) {
        console.error('Error creating borrow request:', error);
        // Handle the error (e.g., showing an error message)
      }
    }
  };
  

  
  const handleOwnerClick = (ownerId) => {
    const secret_key = process.env.REACT_APP_SECRET_KEY || "Yq3t6v9y";
    const encrypted = CryptoJS.AES.encrypt(ownerId.toString(), secret_key).toString();
    const urlSafeBase64String = base64.encode(encrypted, true);


   
    navigate(`/mybooks/${urlSafeBase64String}`);
  };

  const handleRequests = () => {
    navigate(`/requests`);
  };

  useEffect(() => {

    if (!userId) {
      
    } else 
    {
   

    const getBooks = async () => {
      try {
        const response = await axios.get(`${LocalApiUrl}/community/${communityId}`);
        setBooks(response.data.community.books);
        setcommunityName(response.data.community.name);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };
    getBooks();
  

  

  const getUserDetails = async () => {
    try {
      const response = await axios.get(`${LocalApiUrl}/user/details`, {
        params: { email: userId }
      });
      setUserDetails({
        firstName: response.data.firstName,
        lastName: response.data.lastName
      });
      setIsUserDetailsFetched(true);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  if (userId) {
    getUserDetails();
  }
}
}, [communityId, LocalApiUrl, userId, isUserDetailsFetched]);// Add isUserDetailsFetched to the dependencies array

  const handleAddBook = async () => {
    const bookId = uuidv4(); // Generate a unique bookId
    
   
    const bookToAdd = {
      bookId,
      addedBy: userId, // Replace with actual user data
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      lending:newBookData.lending,
      title:newBookData.title,
      author:newBookData.author,
      read: newBookData.read,
      status: newBookData.lending ? 'available' : 'unavailable', // Set status based on lending
      upvotes: 0,
      ...newBookData
    };

    try {
      const response = await axios.post(
        `${LocalApiUrl}/community/${communityId}/add-book`,
        // `${LocalApiUrl}/community/65897773f49e00f955e6e95b/add-book`,
        { book: bookToAdd } // Updated to match backend expectation
      );
      if (response.data.success) {
        setBooks([...books, bookToAdd]);
        setNewBookData({ title: '', author: '', firstName: '', lastName:'',lending: false, read: false }); // Reset fields
       
      } else {
        console.error("Error adding book:", response.data.error);
      }
    } catch (error) {
      console.error("Error adding book:", error);
    }
  };

  if (!userId) {
    return (
      <div className="text-center mt-5">
        <h2>You need to Register/ Login to access this page.</h2>
        
      </div>
    );
  }

  // Render the component
  return (
    
    <div className="community-container container mt-4">
    <div className="text-center mb-5 lg:w-75 xl:w-75 sm:w-100 md:w-100">
      <h1>Welcome to the {communityName} Digital Library</h1>
      <p className='fontsize-16'>You can add your books you wish to lend others, or request from the available book.</p>
    </div>
  
      <div className="add-book-form row g-3 align-items-end">
        {/* Form elements for adding book */}
        <div className="col-md-3">
          <label htmlFor="title" className="form-label">Book Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            placeholder="Title"
            value={newBookData.title}
            onChange={(e) => setNewBookData({ ...newBookData, title: e.target.value })}
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="author" className="form-label">Author</label>
          <input
            type="text"
            className="form-control"
            id="author"
            placeholder="Author"
            value={newBookData.author}
            onChange={(e) => setNewBookData({ ...newBookData, author: e.target.value })}
          />
        </div>
        <div className="col-md-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="lending"
              checked={newBookData.lending}
              onChange={(e) => setNewBookData({ ...newBookData, lending: e.target.checked })}
            />
            <label className="form-check-label" htmlFor="lending">Willing to lend</label>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="read"
              checked={newBookData.read}
              onChange={(e) => setNewBookData({ ...newBookData, read: e.target.checked })}
            />
            <label className="form-check-label" htmlFor="read">Read/ Unread</label>
          </div>
        </div>
        <div className="col-md-2">
          <button type="button" className="btn btn-primary w-100" onClick={handleAddBook}>Add Book</button>
        </div>
      </div>
      <div className="books-grid">
        {books.map((book, index) => (
          <div key={index} className="book-card">
            <div className="book-image-placeholder"> {/* Placeholder for book image */}</div>
            <div className="book-info">
              <h3>{book.title}</h3>
              <p>By {book.author}</p>
              <p className= "profile-click" onClick={() => handleOwnerClick(book.addedBy)}>Owner: {book.firstName} {book.lastName}</p>
              <p>Status: {book.status}</p>
              {book.status === 'available' && book.addedBy !== userId && (
                <Button className="borrow-btn" onClick={() => handleBorrowBook(book)}>Borrow Book</Button>
              )}
            </div>
          </div>
        ))}
      </div>
      
    </div>
  );
};
  
  export default Community;
  
