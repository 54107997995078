import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    city: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const userId = localStorage.getItem("userId");

  const LocalApiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${LocalApiUrl}/user/details/?email=${userId}`);

      if (response.status === 200) {
        setUser(response.data);
      } else if (response.status === 404) {
        // Handle user not found scenario
        console.error("User not found");
      } else {
        // Handle other errors
        console.error("Error fetching user details:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const toggleEditing = () => setIsEditing(!isEditing);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.put(`${LocalApiUrl}/user/update?email=${userId}`, {
          firstName: user.firstName,
          lastName: user.lastName,
          city: user.city,
        });
        fetchUser(); // refresh user data after update
        setIsEditing(false);
      } catch (error) {
        console.error("Error updating user details:", error);
      }
    }
      
  return (
    <div className="container w-50 a-center mt-5">
      <h2>My Profile</h2>
      <div className="card">
        <div className="card-body">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <strong>Email:</strong> {user.email}
            </li>
            {isEditing && (
              <div>
                <div className="form-group mb-3">
                  <label htmlFor="firstName">First Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={user.firstName}
                    onChange={handleInputChange}
                    required={!user.firstName}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="lastName">Last Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={user.lastName}
                    onChange={handleInputChange}
                    required={!user.lastName}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="city">City:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={user.city}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            )}
            {!isEditing && (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <strong>First Name:</strong> {user.firstName}
                </li>
                <li className="list-group-item">
                  <strong>Last Name:</strong> {user.lastName}
                </li>
                <li className="list-group-item">
                  <strong>City:</strong> {user.city}
                </li>
              </ul>
            )}
          </ul>

          {!isEditing && (
            <button className="btn btn-primary mt-3" onClick={toggleEditing}>
              Edit Profile
            </button>
          )}
          {isEditing && (
            <form onSubmit={handleSubmit}>
              <button type="submit" className="btn btn-primary mt-3">
                Save Changes
              </button>
              <button type="button" className="btn btn-secondary mt-3 ms-2" onClick={toggleEditing}>
                Cancel
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;